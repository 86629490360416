<template>
    <div class="scroll-indicator">
        <img class="icon" src="/static/img/scroll-icon.png" />
        <div class="wheel"></div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { TimelineMax } from '@/vendor';

    export default {
        mounted()
        {
            let tl = new TimelineMax({ repeat: -1, yoyo: true });
            tl.to( '.wheel', 1, { y: 7, ease: Expo.easeInOut }, 0 );
        }
    };
</script>
