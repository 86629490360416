<template>
    <div class="css-grid-wrapper" v-if="hasHScroll">
        <div class="scroll-wrapper" @wheel="onWheel" @touchmove="onTouchMove" @touchend="onTouchEnd">
            <div class="scroll-inner" ref="scrollInner">
                <div class="css-grid-main">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="css-grid-main">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        props: {
            hasHScroll: {
                type: Boolean,
                default: false
            }
        },

        mounted()
        {
            if( this.hasHScroll )
            {
                this.offset = 0;
                this.scrollInnerWidth = this.$refs.scrollInner.getBoundingClientRect().width;
            }

            this.onResizeRef = this.onResize;
            window.addEventListener( 'resize', this.onResizeRef );

            this.prevTouch = null;
            // this.onTouchMoveRef = this.onTouchMove;
            // window.addEventListener( 'touchmove', this.onTouchMoveRef );
            // this.onTouchEndRef = this.onTouchEnd;
            // window.addEventListener( 'touchend', this.onTouchEndRef );
        },

        beforeDestroy()
        {
            // window.removeEventListener( 'resize', this.onResizeRef );
            // window.removeEventListener( 'touchmove', this.onTouchMoveRef );
        },

        methods: {
            onWheel( e )
            {
                if( !this.hasHScroll )
                {
                    return;
                }

                this.offset += e.wheelDeltaY || ( -e.deltaY * 25 );

                this.offset = Math.min( 0, this.offset );
                this.offset = Math.max( -this.scrollInnerWidth + window.innerWidth, this.offset );
                let percentage = -this.offset / ( window.innerWidth );

                TweenMax.to( this.$refs.scrollInner, 0.5, { x: this.offset });

                if( this.$route.name === 'PeugeotCaseDetails' )
                {
                    TweenMax.to( '.peugeot-page.details', 0.5, { backgroundPositionX: percentage * 25 } );
                }
            },

            onTouchMove( e )
            {
                if( !this.hasHScroll )
                {
                    return;
                }

                if( !this.prevTouch )
                {
                    this.prevTouch = e.changedTouches[0].clientX;
                    return;
                }

                let current = e.changedTouches[0].clientX;

                this.offset += current - this.prevTouch;

                this.offset = Math.min( 0, this.offset );
                this.offset = Math.max( -this.scrollInnerWidth + window.innerWidth, this.offset );

                TweenMax.to( this.$refs.scrollInner, 0.5, { x: this.offset });

                this.prevTouch = e.changedTouches[0].clientX;
            },

            onTouchEnd()
            {
                this.prevTouch = null;
            },

            onResize()
            {
                if( this.hasHScroll )
                {
                    this.offset = this.offset;
                    this.scrollInnerWidth = this.$refs.scrollInner.getBoundingClientRect().width;
                }
            }
        }
    };
</script>
