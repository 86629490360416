<template>
    <div class="contact-page page-wrapper">
        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>
    .contact-page{
        background:linear-gradient( 45deg, yellow, green );
    }
</style>

<script>
    import NavBar from '@/app/components/NavBar';

    export default {
        components: {
            'nav-bar': NavBar
        }
    };
</script>
