import Vue from 'vue';
import Router from 'vue-router';

import Landing from '@/app/pages/Landing';
import WorkParent from '@/app/pages/WorkParent';
import About from '@/app/pages/About';
import Clients from '@/app/pages/Clients';
import Contact from '@/app/pages/Contact';

import PeugeotCase from '@/app/pages/work/Peugeot';
import PeugeotCaseDetails from '@/app/pages/work/PeugeotDetails';

import AudiCase from '@/app/pages/work/Audi';
import AudiCaseDetails from '@/app/pages/work/AudiDetails';

import SpaCase from '@/app/pages/work/Spa';
import SpaCaseDetails from '@/app/pages/work/SpaDetails';

import IngCase from '@/app/pages/work/Ing';
import IngCaseDetails from '@/app/pages/work/IngDetails';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },
        {
            path: '/work/',
            name: 'Work',
            component: WorkParent,
            children: [
                {
                    path: 'peugeot',
                    name: 'PeugeotCase',
                    component: PeugeotCase
                },
                {
                    path: 'peugeot/details',
                    name: 'PeugeotCaseDetails',
                    component: PeugeotCaseDetails
                },
                {
                    path: 'audi',
                    name: 'AudiCase',
                    component: AudiCase
                },
                {
                    path: 'audi/details',
                    name: 'AudiCaseDetails',
                    component: AudiCaseDetails
                },
                {
                    path: 'spa',
                    name: 'SpaCase',
                    component: SpaCase
                },
                {
                    path: 'spa/details',
                    name: 'SpaCaseDetails',
                    component: SpaCaseDetails
                },

                {
                    path: 'ing',
                    name: 'IngCase',
                    component: IngCase
                },
                {
                    path: 'ing/details',
                    name: 'IngCaseDetails',
                    component: IngCaseDetails
                },
            ]
        },
        {
            path: '/about',
            name: 'About',
            component: About
        },
        {
            path: '/clients',
            name: 'Clients',
            component: Clients
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
