<template>
    <keep-alive>
        <router-view :key="$route.path" ref="childView"></router-view>
    </keep-alive>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';

    export default {
        methods: {
            getAnimateIn()
            {
                this.prevView = this.$refs.childView;

                let tl = new TimelineMax();

                if( typeof this.$refs.childView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.childView.getAnimateIn(), 0 );
                }

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();

                if( typeof this.prevView.getAnimateOut === 'function' )
                {
                    tl.add( this.prevView.getAnimateOut(), 0 );
                }

                return tl;
            }
        }
    };
</script>
