<template>
    <div class="peugeot-page details">
        <ynk-cssgrid-layout :hasHScroll="true" >
            <div class="title">
                <h1>Peugeot<br/>DOOH</h1>
            </div>
            <div class="title-img">
                <img ref="mainImg" src="/static/img/peugeot-visual.jpg" />
            </div>
            <div class="title-panel">
                <div class="actual-panel">
                    <h3>Digital Out Of Home</h3>
                    <p>Meerdere realtime DOOH toepassingen op Dynamic Displays. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Quis ipsum suspendisse ultrices grtavida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="content-block wide" :style="{ '--grid-column': '7 / span 4', '--grid-row': '8 / span 2' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="content-block clear" :style="{ '--grid-column': '9 / span 3', '--grid-row': '3 / span 3' }">
                <div class="content">
                    <ul>
                        <li>Lorem ipsum bladiebla enzo</li>
                        <li>Woorden ipsum dit dat ja</li>
                        <li>Lorem ipsum bladiebla enzo</li>
                        <li>Woorden ipsum dit dat ja</li>
                        <li>Lorem ipsum bladiebla enzo</li>
                        <li>Woorden ipsum dit dat ja</li>
                        <li>Lorem ipsum bladiebla enzo</li>
                        <li>Woorden ipsum dit dat ja</li>
                    </ul>
                </div>
            </div>

            <div class="content-block tall" :style="{ '--grid-column': '13 / span 2', '--grid-row': '2 / span 5' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="content-block wide" :style="{ '--grid-column': '12 / span 5', '--grid-row': '8 / span 2' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="content-block wide" :style="{ '--grid-column': '17 / span 5', '--grid-row': '2 / span 2' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="content-block wide" :style="{ '--grid-column': '17 / span 5', '--grid-row': '2 / span 2' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="content-block clear" data-index="7" :style="{ '--grid-column': '19 / span 5', '--grid-row': '6 / span 5' }">
                <img src="/static/img/peugeot-visual.jpg" />
                <div class="content">
                    <h3>Bla bla bla</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </ynk-cssgrid-layout>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';
    import CssGrid from '@/app/layouts/CssGrid';
    import NavBar from '@/app/components/NavBar';

    export default {
        name: 'PeugeotCaseDetail',

        components: {
            'ynk-cssgrid-layout': CssGrid,
            'nav-bar': NavBar
        },

        methods: {
            getAnimateIn()
            {
                let tl = new TimelineMax();
                tl.from( this.$el, 0.5, { autoAlpha: 0 }, 0 );

                tl.staggerFrom( this.$el.querySelectorAll( '.css-grid-main > *:not(.title)' ), 0.3, { x: -20, opacity: 0 }, 0.1, 0 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();
                tl.to( this.$el, 0.5, { autoAlpha: 0 }, 0 );
                return tl;
            }
        }
    };
</script>
