<template>
    <div class="nav-bar">
        <v-scroll-indicator></v-scroll-indicator>

        <div class="custom-css-grid">
            <div :class="{ 'block': true, 'active': currentRoute.indexOf( '/work' ) > -1 }" @click="doScroll('work')">
                <div class="blokje"></div>
                <span>Work</span>
            </div>

            <div :class="{ 'block': true, 'active': currentRoute === '/about' }" @click="doScroll('about')">
                <div class="blokje"></div>
                <span>About</span>
            </div>

            <div class="block logo">
                <svg version="1.1" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="117.25px" height="37.96px" viewBox="0 0 117.25 37.96" style="enable-background:new 0 0 117.25 37.96;"
                     xml:space="preserve">
                    <defs>
                    </defs>
                    <polygon class="st0" points="0,0 34.16,0 34.16,37.96 14.65,37.96 14.65,18.98 "/>
                    <polygon class="st0" points="79.3,0 117.25,0 102.59,18.98 117.25,37.96 79.3,37.96 "/>
                    <polygon class="st0" points="37.75,0 57.59,12.94 57.59,0 75.71,0 75.71,37.96 37.75,37.96 "/>
                </svg>

            </div>

            <div :class="{ 'block': true, 'active': currentRoute === '/clients' }" @click="doScroll('clients')">
                <div class="blokje"></div>
                <span>Clients</span>
            </div>

            <div :class="{ 'block': true, 'active': currentRoute === '/contact' }" @click="doScroll('contact')">
                <div class="blokje"></div>
                <span>Contact</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .st0{
        fill-rule:evenodd;
        clip-rule:evenodd;
    }
</style>

<script>
    import ScrollIndicator from '@/app/components/ScrollIndicator';

    export default {
        components: {
            'v-scroll-indicator': ScrollIndicator
        },

        data()
        {
            return {
                appType: 'clean'
            };
        },

        computed: {
            currentRoute()
            {
                return this.$route.path;
            }
        },

        mounted()
        {
            // init
        },

        methods: {
            doRoute( pRoute )
            {
                if( pRoute === this.$route.path )
                {
                    return;
                }

                this.$router.push( pRoute );
            },

            doScroll( pRoute )
            {
                let links = {
                    'work': '.work1-view',
                    'about': '.about-view',
                    'clients': '.clients-view',
                    'contact': '.contact-view'
                }

                let section = document.querySelector( links[ pRoute ] );
                TweenMax.set( 'html, body', { scrollTop: section.parentNode.offsetTop });
            }
        }
    };
</script>
