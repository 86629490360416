<template>
    <div class="about-page page-wrapper">
        <ynk-css-grid>
            <div class="title">
                <h1>YNK - Digital Frontrunners</h1>
            </div>
            <div class="title-panel">
                <div class="actual-panel">
                    <h3>Wij veranderen zand in kastelen</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius pretium nisi. Duis eu nisi eleifend, consectetur mauris vitae, rutrum felis. Quisque eu quam magna. Cras a interdum nisi. In malesuada vitae. </p>

                    <p>Nulla et semper. Integer dictum tellus vitae eros tincidunt, a iaculis magna vulputate. Praesent justo velit, mollis a felis a, finibus fermentum ipsum. Pellentesque facilisis risus purus, ut placerat sapien sodales eget. </p>

                    <p>Integer sodales tempus vehicula. Ut facilisis lorem est, nec lacinia metus ultrices in. Praesent a turpis pharetra, volutpat metus vel, venenatis sem.</p>

                    <p>David is een pannenkoek.</p>
                </div>
            </div>

            <div class="tech-grid">
                <div v-for="tech in techs" :class="{ 'block': true, 'wide': tech.wide }" :style="{ 'grid-column-start': tech.x, 'grid-row-start': tech.y }">
                    <div class="icon-wrapper">
                        <img :src="'/static/img/icons/' + tech.assetName + '.svg'" />
                    </div>
                    <p>{{ tech.name }}</p>
                </div>

                <div class="block">
                    <img src="/static/img/roosje.png" />
                    <p>Challenge us with the seemingly impossible</p>
                </div>
            </div>
        </ynk-css-grid>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import CssGrid from '@/app/layouts/CssGrid';
    import NavBar from '@/app/components/NavBar';

    export default {
        components: {
            'ynk-css-grid': CssGrid,
            'nav-bar': NavBar
        },

        data()
        {
            return {
                techs: [
                    {
                        name: 'Digital Out Of Home',
                        assetName: 'dooh',
                        wide: true
                    },
                    {
                        name: 'Custom Arcade Kasten',
                        assetName: 'arcade-kasten',
                        wide: true
                    },
                    {
                        name: 'WebGL',
                        assetName: 'webgl',
                        wide: false
                    },
                    {
                        name: 'Chat Banners',
                        assetName: 'chat-banners',
                        wide: false
                    },
                    {
                        name: 'Game Banners',
                        assetName: 'game-banners',
                        wide: false
                    },
                    {
                        name: 'Voice Assistants',
                        assetName: 'voice-assistants',
                        wide: true
                    },
                    {
                        name: 'Chat Bots',
                        assetName: 'chat-bots',
                        wide: false
                    },
                    {
                        name: 'Progressive Web Apps',
                        assetName: 'pwa',
                        wide: true
                    },
                    {
                        name: 'Physical Digital Experiences',
                        assetName: 'physical-digital-experiences',
                        wide: true
                    },
                    {
                        name: 'Portable Games',
                        assetName: 'portable-games',
                        wide: true
                    },
                    {
                        name: 'Rich Media Banners',
                        assetName: 'rich-media-banners',
                        wide: true
                    },
                    {
                        name: 'Campaign Websites',
                        assetName: 'campaign-website',
                        wide: true
                    },
                    {
                        name: 'Marketing Games',
                        assetName: 'marketing-games',
                        wide: true
                    },
                    {
                        name: '3D Banners',
                        assetName: 'banners-3d',
                        wide: false
                    },
                    {
                        name: 'Ideas',
                        assetName: 'ideas',
                        wide: false
                    }
                ]
            }
        },

        mounted()
        {
            this.buildGrid();
        },

        methods: {
            buildGrid()
            {
                this.currentOffsetX = 1; // 1 based
                this.currentOffsetY = 1; // 1 based
                let width = 6; // available width

                for( let i = 0; i < this.techs.length; i++ )
                {
                    this.techs[i].x = this.currentOffsetX;
                    this.techs[i].y = this.currentOffsetY;

                    if( this.techs[i].wide )
                    {
                        this.currentOffsetX += 2;
                    }
                    else
                    {
                        this.currentOffsetX ++;
                    }

                    if( this.currentOffsetX > width )
                    {
                        this.currentOffsetX = 1;
                        this.currentOffsetY ++;
                    }
                }
            }
        }
    };
</script>
