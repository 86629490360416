<template>
    <div id="app">
        <!-- <ynk-layout ref="mainLayout" :prevRoute="prevRoute"> -->
            <transition v-if="isDetailRoute" mode="in-out" @enter="getAnimateIn" @leave="getAnimateOut" :css="false" appear>
                <router-view ref="currentDetailView"></router-view>
            </transition>
            <template v-else>
                <div class="page-parent"><v-landing ref="landing" class="landing-view"></v-landing></div>
                <div class="page-parent"><v-peugeot-case ref="work1" class="work1-view" ></v-peugeot-case></div>
                <div class="page-parent"><v-audi-case ref="work2" class="work2-view" ></v-audi-case></div>
                <div class="page-parent"><v-spa-case ref="work3" class="work3-view" ></v-spa-case></div>
                <div class="page-parent"><v-about ref="about" class="about-view" ></v-about></div>
                <div class="page-parent"><v-clients ref="clients" class="clients-view" ></v-clients></div>
                <div class="page-parent"><v-contact ref="contact" class="contact-view" ></v-contact></div>
            </template>
        <!-- </ynk-layout> -->
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';

    .page-parent{
        height:300vh;
        margin-bottom:-100vh;
        box-sizing:content-box;

        .page-wrapper{
            &>div{
                // height:100vh;
            }
        }
    }

    .clip{
        mask-image: url( '/static/img/ynk-logo.svg' );
        mask-repeat:no-repeat;
        mask-position:58% top;
    }
</style>

<script>
    import Vue from 'vue';
    import { TimelineMax } from '@/vendor';
    import Main from '@/app/layouts/Main';

    import NavBar from '@/app/components/NavBar';
    import LandingPage from '@/app/pages/Landing';
    import PeugeotCase from '@/app/pages/work/Peugeot';
    import AudiCase from '@/app/pages/work/Audi';
    import SpaCase from '@/app/pages/work/Spa';
    import AboutPage from '@/app/pages/About';
    import ClientsPage from '@/app/pages/Clients';
    import ContactPage from '@/app/pages/Contact';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main,
            'nav-bar': NavBar,

            'v-landing': LandingPage,
            'v-peugeot-case': PeugeotCase,
            'v-audi-case': AudiCase,
            'v-spa-case': SpaCase,
            'v-about': AboutPage,
            'v-clients': ClientsPage,
            'v-contact': ContactPage,
        },

        data()
        {
            return {
                page: 0
            }
        },

        computed: {
            isDetailRoute()
            {
                return this.$route.path.indexOf( 'details' ) > -1;
            }
        },

        watch: {
            '$route.path': function( pVal )
            {
                // console.log( 'route path changed', pVal, arguments );
            },

            'page': function( pVal, pOldVal )
            {
                // prevent running out of routes array
                if( pVal === this.routes.length )
                {
                    return;
                }

                for( let i = pVal; i >= 0; i-- )
                {
                    // if scrolled past these pages, set progress on 1
                    if( this.routes[i].anim.progress() < 1 )
                    {
                        this.routes[i].anim.progress( 1 );
                    }
                }

                this.triggerRoute();
            },

            'isDetailRoute': function( pVal )
            {
                if( pVal === false )
                {
                    // if we routed back to the main scroll thing
                    // rebuild anims
                    Vue.nextTick( this.buildAnims );
                }
            }
        },

        mounted()
        {
            // big yikes
            this.routes = [
                {
                    path: '/',
                    comp: this.$refs.landing,
                    name: 'landing',
                    anim: null
                },
                {
                    path: '/work/peugeot',
                    comp: this.$refs.work1,
                    name: 'work1',
                    anim: null
                },
                {
                    path: '/work/audi',
                    comp: this.$refs.work2,
                    name: 'work2',
                    anim: null
                },
                {
                    path: '/work/spa',
                    comp: this.$refs.work3,
                    name: 'work3',
                    anim: null
                },
                {
                    path: '/about',
                    comp: this.$refs.about,
                    name: 'about',
                    anim: null
                },
                {
                    path: '/clients',
                    comp: this.$refs.clients,
                    name: 'clients',
                    anim: null
                },
                {
                    path: '/contact',
                    comp: this.$refs.contact,
                    name: 'contact',
                    anim: null
                },
            ];

            this.scrollRef = this.onScroll;
            window.addEventListener( 'scroll', this.scrollRef );

            if( this.$route.path.indexOf( 'details' ) === -1 )
            {
                this.handleDeeplink();
                Vue.nextTick( this.buildAnims ); // has to wait for the $refs to update (so they have an $el)
            }

            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        beforeDestroy()
        {
            window.removeEventListener( 'scroll', this.scrollRef );
        },

        methods: {
            handleDeeplink()
            {
                // scrolls you to the proper page when deeplinking to a 1 level deep route (/work, /about)
                let deeplink = this.routes.find( item => {
                    return item.path === this.$route.path;
                });

                if( deeplink.path !== '/' )
                {
                    window.scrollTo( 0, deeplink.comp.$el.offsetTop );
                }
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            resetCompLinks()
            {
                // check if linkage to comps still exists, if not, remake them
                // this is needed for the anims
                if( this.$refs[ this.routes[ this.page ].name ] )
                {
                    // if not, rebind them
                    if( !this.routes[ this.page ].comp || this.$refs[ this.routes[ this.page ].name ]._uid !== this.routes[ this.page ].comp._uid )
                    {
                        for( let i = 0; i < this.routes.length; i++ )
                        {
                            this.routes[i].comp = this.$refs[ this.routes[i].name ];
                        }

                        this.buildAnims();
                    }
                }
            },

            onScroll( e )
            {
                // check if the component refs are still the same in the data.
                this.resetCompLinks();

                // if in detail views, dont perform scroll actions
                if( this.$route.path.indexOf( 'details' ) > -1 )
                {
                    return;
                }

                let offset = window.scrollY / ( window.innerHeight * 2 );
                if( Math.floor( offset ) !== this.page )
                {
                    // determine if the offset should trigger new route
                    this.page = Math.floor( offset );
                }

                this.page = Math.min( this.page, this.routes.length - 1 );

                let pageProgress = ( offset - this.page); // * 2; // if you add * 2 to the end, you get a range from 0-2, splitting at the point where the next view comes flying in
                let animProgress = ( offset - this.page - 0.5 ) * 2;

                if( this.page + 1 < this.routes.length )
                {
                    this.clipAnim( this.page + 1, Math.max( animProgress, 0 ) );
                }

                if( typeof this.routes[ this.page ].comp.seekAnim === 'function' )
                {
                    this.routes[ this.page ].comp.seekAnim( pageProgress );
                }
            },

            triggerRoute()
            {
                // this happens automatically when this.page changes
                if( this.$route.path !== this.routes[ this.page ].path )
                {
                    this.$router.push( this.routes[ this.page ].path );
                }
            },

            buildAnims()
            {
                // for every route, prepare a timeline so we can seek through it on scroll

                for( let i = 0; i < this.routes.length; i++ )
                {
                    let tl = new TimelineMax({ paused: true });

                    tl.add( () =>
                    {
                        this.routes[i].comp.$el.classList.add( 'clip' );
                    }, 0 );

                    tl.fromTo( this.routes[i].comp.$el, 1.5, { '-webkit-mask-size': '0vw' }, { '-webkit-mask-size': '1000vw', ease: Expo.easeIn }, 0 );
                    tl.fromTo( this.routes[i].comp.$el, 1.5, { '-webkit-mask-position-x': '50%' }, { '-webkit-mask-position-x': '58%', ease: Sine.easeIn }, 0 );

                    // tl.fromTo( this.routes[i].comp.$el.children, 0.3, { opacity: 0 }, { opacity: 1, ease: Sine.easeIn }, 1.2 );

                    tl.add( () =>
                    {
                        this.routes[i].comp.$el.classList.add( 'clip' );
                    }, 1.49 );
                    tl.add( () =>
                    {
                        this.routes[i].comp.$el.classList.remove( 'clip' );
                    }, 1.5 );

                    this.routes[ i ].anim = tl;
                }
            },

            clipAnim( page, progress )
            {
                // this gets triggered on scroll
                this.routes[ page ].anim.progress( progress );
            },

            getAnimateIn( el, done )
            {
                console.log( 'anim in?', el );
                let tl = new TimelineMax({ onComplete: done });
                tl.add( this.$refs.currentDetailView.getAnimateIn(), 0 );
                // tl.to({ foo: 0 }, 2, { foo: 1 }, 0 );
            },

            getAnimateOut( el, done )
            {
                console.log( 'anim out', el );
                let tl = new TimelineMax({ onComplete: done });
                tl.add( this.$refs.currentDetailView.getAnimateOut(), 0 );
                // tl.to({ foo: 0 }, 2, { foo: 1 }, 0 );
            }
        }
    };
</script>
