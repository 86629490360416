<template>
    <div class="spa-page page-wrapper">
        <ynk-cssgrid-layout>
            <div class="title">
                <h1>Spa <br/>Kleur je <br class="small-only" />routine</h1>
            </div>
            <div class="title-panel">
                <div class="actual-panel">
                    <h3>Actie website</h3>
                    <p>Gesimuleerd 3D effect met behulp van shaders voor de Spa actie website</p>
                </div>

                <router-link tag="button" class="view-case-button" to="/work/spa/details">View case</router-link>
            </div>
        </ynk-cssgrid-layout>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';
    import CssGrid from '@/app/layouts/CssGrid';
    import NavBar from '@/app/components/NavBar';

    export default {
        name: 'SpaCase',

        components: {
            'ynk-cssgrid-layout': CssGrid,
            'nav-bar': NavBar
        },

        methods: {
            getAnimateIn()
            {
                let tl = new TimelineMax();

                // tl.staggerFrom( this.$el.querySelectorAll( '.title-panel, .title' ), 0.5, { x: -50, autoAlpha: 0 }, 0 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();
                return tl;
            }
        }
    };
</script>
