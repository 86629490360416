<template>
    <div class="main-layout">
        <div class="main-content">
            <slot ref="currentPage"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TweenMax, TimelineMax } from '@/vendor';

    export default {
        props: {
            prevRoute: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            getAnimateIn( el )
            {
                TweenMax.set( el, { zIndex: 2, className: '+=clip' });

                let tl = new TimelineMax();
                if( this.prevRoute.name === 'Landing' )
                {
                    tl.fromTo( el, 0.5, { opacity: 0 }, { opacity: 1 }, 0 );
                    tl.fromTo( el, 1.5, { '-webkit-mask-size': '50vw' }, { '-webkit-mask-size': '2000vw', ease: Expo.easeIn }, 0.5 );
                }
                else
                {
                    tl.fromTo( el, 1.5, { '-webkit-mask-size': '0vw' }, { '-webkit-mask-size': '2000vw', ease: Expo.easeIn }, 0 );
                }

                tl.add( () =>
                {
                    el.classList.remove( 'clip' );
                }, 2 );

                return tl;
            },

            getAnimateInReverse( el )
            {
                TweenMax.set( el, { zIndex: 1, position: 'absolute', top: 0 });

                let tl = new TimelineMax();
                tl.to({ foo: 0 }, 2, { foo: 1 }, 0 );

                return tl;
            },

            getAnimateOut( el )
            {
                TweenMax.set( el, { zIndex: 1, position: 'absolute' });

                let tl = new TimelineMax();
                tl.to({ foo: 0 }, 2, { foo: 1 }, 0 );

                return tl;
            },

            getAnimateOutReverse( el )
            {
                TweenMax.set( el, { zIndex: 2, className: '+=clip', pointerEvents: 'none' } );

                let tl = new TimelineMax();
                if( this.$route.name === 'Landing' )
                {
                    // anim to half the thing and then fill black;
                    tl.fromTo( el, 1.5, { '-webkit-mask-size': '800vw' }, { '-webkit-mask-size': '50vw', ease: Expo.easeOut }, 0.5 );
                    tl.fromTo( el, 0.5, { opacity: 1 }, { opacity: 0, ease: Expo.easeOut }, 2 );
                }
                else
                {
                    tl.fromTo( el, 1.5, { '-webkit-mask-size': '800vw' }, { '-webkit-mask-size': '0vw', ease: Expo.easeOut }, 0 );
                }

                tl.add( () =>
                {
                    el.classList.remove( 'clip' );
                }, 2.5 );

                return tl;

            }
        }
    };
</script>
