<template>
    <div class="clients-page page-wrapper">
        <ynk-css-grid>
            <div class="title">
                <h1>Nailed projects with</h1>
            </div>

            <div class="client-grid">
                <div v-for="client in clients" :class="{ 'block': true, 'wide': client.wide }" :style="{ 'grid-column-start': client.x, 'grid-row-start': client.y }">
                    <!-- <p>{{ client.name }}</p> -->
                    <img :src="'/static/img/clients/' + client.src" />
                </div>
            </div>

            <div class="payoff">
                <h2>Are you next?</h2>
            </div>
        </ynk-css-grid>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import CssGrid from '@/app/layouts/CssGrid';
    import NavBar from '@/app/components/NavBar';

    export default {
        components: {
            'ynk-css-grid': CssGrid,
            'nav-bar': NavBar
        },

        data()
        {
            return {
                clients: [
                    {
                        name: 'Abovo media',
                        src: 'abovo-media.png',
                        wide: false
                    },
                    {
                        name: 'Green orange',
                        src: 'green-orange.png',
                        wide: true
                    },
                    {
                        name: 'lbi',
                        src: 'lbi.png',
                        wide: false
                    },
                    {
                        name: 'xxs',
                        src: 'xxs.png',
                        wide: false
                    },
                    {
                        name: 'ogilvy',
                        src: 'ogilvy.png',
                        wide: false
                    },
                    {
                        name: 'Brand webbing',
                        src: 'brand-webbing.png',
                        wide: true
                    },
                    {
                        name: 'blue mango',
                        src: 'blue-mango.png',
                        wide: true
                    },
                    {
                        name: 'Blossom',
                        src: 'blossom.png',
                        wide: false
                    },
                    {
                        name: 'brandbase',
                        src: 'brandbase.png',
                        wide: true
                    },
                    {
                        name: 'noprotocol',
                        src: 'noprotocol.png',
                        wide: true
                    },
                    {
                        name: 'dorst & lesser',
                        src: 'dorst-lesser.png',
                        wide: false
                    },
                    {
                        name: 'proof',
                        src: 'proof.png',
                        wide: false
                    },
                    {
                        name: 'warnerbros',
                        src: 'warnerbros.png',
                        wide: false
                    },
                    {
                        name: 'fitzroy',
                        src: 'fitzroy.png',
                        wide: false
                    },
                    {
                        name: 'Abovo media',
                        src: 'abovo-media.png',
                        wide: false
                    },
                    {
                        name: 'blue mango',
                        src: 'blue-mango.png',
                        wide: true
                    },
                    {
                        name: 'Blossom',
                        src: 'blossom.png',
                        wide: false
                    },
                    {
                        name: 'fitzroy',
                        src: 'fitzroy.png',
                        wide: false
                    },
                    {
                        name: 'Abovo media',
                        src: 'abovo-media.png',
                        wide: false
                    },
                    {
                        name: 'Green orange',
                        src: 'green-orange.png',
                        wide: true
                    },
                    {
                        name: 'lbi',
                        src: 'lbi.png',
                        wide: false
                    },
                    {
                        name: 'xxs',
                        src: 'xxs.png',
                        wide: false
                    },
                    {
                        name: 'ogilvy',
                        src: 'ogilvy.png',
                        wide: false
                    },
                    {
                        name: 'Brand webbing',
                        src: 'brand-webbing.png',
                        wide: true
                    },
                    {
                        name: 'blue mango',
                        src: 'blue-mango.png',
                        wide: true
                    },
                    {
                        name: 'Blossom',
                        src: 'blossom.png',
                        wide: false
                    },
                    {
                        name: 'brandbase',
                        src: 'brandbase.png',
                        wide: true
                    },
                    {
                        name: 'noprotocol',
                        src: 'noprotocol.png',
                        wide: true
                    },
                    {
                        name: 'dorst & lesser',
                        src: 'dorst-lesser.png',
                        wide: false
                    }
                ]
            }
        },

        mounted()
        {
            this.buildGrid();
        },

        methods: {
            buildGrid()
            {
                this.currentOffsetX = 1; // 1 based
                this.currentOffsetY = 1; // 1 based
                let width = 10; // available width

                for( let i = 0; i < this.clients.length; i++ )
                {
                    this.clients[i].x = this.currentOffsetX;
                    this.clients[i].y = this.currentOffsetY;

                    if( this.clients[i].wide )
                    {
                        this.currentOffsetX += 2;
                    }
                    else
                    {
                        this.currentOffsetX ++;
                    }

                    if( this.currentOffsetX > width )
                    {
                        this.currentOffsetX = 1;
                        this.currentOffsetY ++;
                    }
                }
            }
        }
    };
</script>
