<template>
    <div class="audi-page page-wrapper">
        <ynk-cssgrid-layout>

            <div class="title">
                <h1>Audi <br/>Zomersets</h1>
            </div>
            <div class="title-panel">
                <div class="actual-panel">
                    <h3>Display campagne</h3>
                    <p>Unieke toepassing van 3D in online display campagne voor Audi</p>

                    <!-- <router-link to="/work/spa">Next case</router-link> -->
                </div>

                <router-link tag="button" class="view-case-button" to="/work/audi/details">View case</router-link>
            </div>
            <div class="visual">
                <img src="/static/img/audi.png" />
            </div>

        </ynk-cssgrid-layout>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import NavBar from '@/app/components/NavBar';
    import CssGrid from '@/app/layouts/CssGrid';

    export default {
        name: 'AudiCase',

        components: {
            'nav-bar': NavBar,
            'ynk-cssgrid-layout': CssGrid
        },

        methods: {
            getAnimateIn()
            {
                let tl = new TimelineMax();

                // tl.staggerFrom( this.$el.querySelectorAll( '.title-panel, .title' ), 0.5, { x: -50, autoAlpha: 0 }, 0 );
                // tl.from( this.$el.querySelector( '.visual' ), 0.5, { x: 50, autoAlpha: 0 }, 0.3 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();
                return tl;
            }
        }
    };
</script>
