<template>
    <div class="landing-page page-wrapper">
        <div class="visual">
            <img class="ynk-logo" src="/static/img/ynk-logo.svg" />
            <h1>Digital Frontrunners</h1>
        </div>

        <nav-bar></nav-bar>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import NavBar from '@/app/components/NavBar';

    export default {
        components: {
            'nav-bar': NavBar
        }
    };
</script>
